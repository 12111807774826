<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">线下预约</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit"> 查询 </a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="会员卡">
					<a-input v-model="query.member_card" placeholder="请输入会员卡号"></a-input>
				</a-form-model-item>
				<a-form-model-item label="会员电话">
					<a-input v-model="query.phone" placeholder="请输入手机号"></a-input>
				</a-form-model-item>
				<a-form-model-item label="服务名称">
					<a-input v-model="query.service_name" placeholder="请输入"></a-input>
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-row class="table-action-list" style="margin-top: 20px">
			<a-button type="primary" @click="add" style="margin-left: 10px">+新增记录</a-button>
		</a-row>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px"
			v-viewer>
			<span slot="money" slot-scope="text, record">
				{{ record.money }} 元
			</span>
			<span slot="action" slot-scope="text, record">
				<a-space>
					<a @click="toEdit(record)">详情</a>

				</a-space>
			</span>
		</a-table>

		<!-- 抽屉1 添加/修改 -->
		<a-drawer :title="(customer_id == null ? '新增' : '详情')" :width="720" :visible="visible1"
			:body-style="{ paddingBottom: '80px' }" @close="onClose1">

			<a-form-model :model="customerForm" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-model-item label="会员手机号" prop="customer_id">
					<a-select show-search :value="customerForm.customer_id" placeholder="请选择会员手机号" style="width: 390px"
						:default-active-first-option="false" :show-arrow="false" :filter-option="false"
						:not-found-content="null" @search="handleSearchCustomer" @change="handleChangeCustomer"
						@blur="blur">
						<a-select-option v-for="d in customerList" :key="d.customer_id">
							{{ d.phone }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item ref="name" label="会员姓名" prop="name">
					<a-input v-model="customerForm.name" @blur="() => {
						$refs.name.onFieldBlur();
					}
						" disabled />
				</a-form-model-item>
				<a-form-model-item label="服务名称" prop="service_id">
					<a-select show-search :value="customerForm.service_id" placeholder="请选择服务名称" style="width: 390px"
						:default-active-first-option="false" :show-arrow="false" :filter-option="false"
						:not-found-content="null" @search="handleSearchCustomer2" @change="handleChangeCustomer2"
						@blur="blur2">
						<a-select-option v-for="d in levelList" :key="d.service_id">
							{{ d.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item ref="res_time_str" label="服务时间" prop="res_time_str">
					<a-input v-model="customerForm.res_time_str" @blur="() => {
						$refs.name.onFieldBlur();
					}
						" placeholder="请输入服务时间" />
				</a-form-model-item>
				<a-form-model-item ref="money" label="服务费用" prop="money">
					<a-input suffix="元" v-model="customerForm.money" @blur="() => {
						$refs.name.onFieldBlur();
					}
						" placeholder="请输入服务金额" />
				</a-form-model-item>
				<a-form-model-item label="服务备注" prop="content">
					<a-input v-model="customerForm.content" type="textarea" placeholder="请输入"
						:autosize="{ minRows: 2, maxRows: 100 }" />
				</a-form-model-item>
			</a-form-model>

			<div :style="{
				position: 'absolute',
				right: 0,
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e9e9e9',
				padding: '10px 16px',
				background: '#fff',
				textAlign: 'right',
				zIndex: 1,
			}
				">
				<a-button :style="{ marginRight: '8px' }" @click="onClose1">
					取消
				</a-button>
				<a-button type="primary" @click="submitForm">
					确定
				</a-button>
			</div>
		</a-drawer>

		<!-- 抽屉2 查看详情 -->
		<a-drawer title="预约详情" :width="550" :visible="visible2" :body-style="{ paddingBottom: '80px' }" @close="onClose2">
			<a-descriptions :column="{ xs: 100000, sm: 10000, md: 1 }" bordered>
				<a-descriptions-item label=" 服务类型" :span="1">
					{{ item.type == 1 ? "会员套餐" : item == 2 ? "付费服务" : "线下提交" }}
				</a-descriptions-item>
				<a-descriptions-item label="订单时间">
					{{ item.create_at_str }}
				</a-descriptions-item>
				<a-descriptions-item label="服务名称">
					{{ item.service_name }}
				</a-descriptions-item>
				<a-descriptions-item label="用户姓名">
					{{ item.user_name }}
				</a-descriptions-item>
				<a-descriptions-item label="用户电话">
					{{ item.user_phone }}
				</a-descriptions-item>
				<a-descriptions-item label="基本情况描述">
					{{ item.content }}
				</a-descriptions-item>
			</a-descriptions>
			<div :style="{
				position: 'absolute',
				right: 0,
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e9e9e9',
				padding: '10px 16px',
				background: '#fff',
				textAlign: 'right',
				zIndex: 1,
			}
				">
				<a-button :style="{ marginRight: '8px' }" @click="onClose2">
					取消
				</a-button>
				<a-button type="primary" @click="onClose2">
					确定
				</a-button>
			</div>
		</a-drawer>
	</div>
</template>

<script>
import { domain } from '@/siteInfo.js'

export default {
	data() {
		let validateClass = (rule, value, callback) => {
			if (value) {
				callback(); // 通过验证
			} else {
				callback(new Error('请选择分类')); // 验证失败，返回错误信息
			}
		};
		let validateMoney = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入金额'));
			} else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
				callback(new Error('请输入正确的金额'));
			} else {
				callback();
			}
		};
		return {
			query: {
				page: 1,
				limit: 10,
				type: "offline"
			},
			customerList: [],
			customer_id: null,
			pagetotal: 0,
			inputValue: '',
			visible1: false,
			visible2: false,
			item: {},
			labelCol: { span: 4 },
			wrapperCol: { span: 14 },
			customerForm: {
				customer_id: null,
				money: null,
				content: '',
			},
			service_id: null,
			// rules: {
			// 	name: [
			// 		{ required: true, message: '请输入服务名称', trigger: 'blur' },
			// 	],
			// 	money: [
			// 		{ required: true, message: '请输入金额', trigger: 'blur' },
			// 		{ validator: validateMoney, trigger: 'blur' },
			// 	],
			// 	class_id: [{ required: true, validator: validateClass, message: "请选择类型", trigger: 'change' }],
			// 	content: [
			// 		{ required: true, message: '请输入服务介绍', trigger: 'blur' },
			// 		{ max: 255, message: '服务介绍仅限255字', trigger: 'blur' },
			// 	],
			// },

			columns: [
				{
					title: '会员姓名',
					dataIndex: 'name',
				},
				{
					title: '会员卡',
					dataIndex: 'member_card',
				},
				{
					title: '会员电话',
					dataIndex: 'phone',
				},
				{
					title: '服务名称',
					dataIndex: 'service_name',
				},
				{
					title: '预约时间',
					dataIndex: 'res_time_str',
				},
				{
					title: '服务费用',
					scopedSlots: {
						customRender: 'money'
					}
				},
				{
					title: '服务人员',
					dataIndex: 'admin_name'
				},
				{
					title: '创建时间',
					dataIndex: 'create_at_str',
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action',
					},
				},
			],
			classColumns: [
				{
					title: '名称',
					dataIndex: 'name',
					customRender: (text, record, index) => {
						return {
							children: text,
							attrs: {
								style: 'width: 60%;', // 指定列宽度为 30%
							},
						};
					},
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action',
					},
				},],
			data: [],
			levelList: [],
			selectedRowKeys: []
		}
	},
	created() {
		this.getList()
	},
	methods: {
		handleSearchCustomer(value) {
			this.getCustomerList(value)
		},
		handleChangeCustomer(value) {
			console.log("change", value);
			this.customerForm.customer_id = value;
			this.customerForm.name = this.getName(this.customerList, value, "customer_id", "name");
		},
		blur() {
			console.log("失去焦点")
			this.getCustomerList('')
		},
		handleSearchCustomer2(value) {
			this.getLevelList(value)
		},
		handleChangeCustomer2(value) {
			console.log("change222", value);
			this.customerForm.service_id = value;
		},
		blur2() {
			console.log("失去焦点")
			this.getLevelList('')
		},
		getCustomerList(phone) {
			this.$post(domain + '/admin/v1/customer/listNoPage', { "phone": phone }).then(res => {
				if (res.code === 0) {
					this.customerList = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		toEdit(record) {
			this.reservation_id = record.reservation_id
			this.$post(domain + '/admin/v1/reservation/info', { reservation_id: record.reservation_id }).then(res => {
				if (res.code === 0) {
					this.item = res.data
					this.visible2 = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		submitForm() {
			this.$post(domain + '/admin/v1/reservation/submitByOffLine', this.customerForm).then(res => {
				if (res.code === 0) {
					this.$message.success(res.msg)
					this.visible1 = false
					this.initForm()
					this.getList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit,
				type: "offline"
			}
			this.getList()
		},
		handleChange(value) {
			console.log(`Selected: ${value}`);
		},
		popupScroll() {
			console.log('popupScroll');
		},
		getName(arr, id, idName, lableName) { //通过id获取数组名称
			if (id || id == '0') {
				const index = arr.findIndex((role) => role[idName] === id)
				return index >= 0 ? arr[index][lableName] : undefined
			} else {
				return undefined
			}
		},
		add() {
			this.visible1 = true;
			this.getCustomerList("")
			this.getLevelList("")
		},
		onClose1() {
			this.visible1 = false;
			this.initForm()
		},
		onClose2() {
			this.visible2 = false;
		},
		initForm() {
			this.service_id = null
			this.customerForm = {
				customer_id: null,
				money: null,
				content: '',
			}
		},
		toOrder(item) {
			if (item.rent_count > 0) {
				this.$router.push({
					path: '/orderList',
					query: {
						cid: item.customer_id
					}
				})
			} else {
				this.$message.warn("暂未产生订单")
			}
		},
		getList() {
			this.$post(domain + '/admin/v1/reservation/list', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},

		getLevelList(name) {
			this.$post(domain + '/admin/v1/Service/listNoPage', { name: name }).then(res => {
				if (res.code === 0) {
					this.levelList = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList()
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList()
			console.log(query)
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4e80f8;
	}

	.title {
		color: #383f50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
